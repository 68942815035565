// src/context/AuthContext.js (or .ts if you're using TypeScript)
import React, { useContext, useState, useEffect, createContext } from 'react';
import { User } from 'firebase/auth';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

// Define a type for the auth context
type AuthContextType = {
  currentUser: User | null;
  // Add other fields and functions as needed
};

// Create the context with the initial value as undefined
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
