import React, {useEffect} from 'react';
import { useAuth } from '../context/AuthContext';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const LandingPage: React.FC = () => {
  const { currentUser } = useAuth();
  const auth = getAuth();
    useEffect(() => {
      console.log(currentUser);
  }, [currentUser])
  const handleGoogleSignIn = () => {
      const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        // You can use the Google Access Token to access the Google API if needed
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // The email of the user's account used.
        // The AuthCredential type that was used.
        // ...
      });
  };

  return (
    <div className="landing-page">
      {currentUser ? (
        <div>
                  <h1>Welcome, {currentUser.displayName || 'User'}!</h1>
                  <img src={currentUser.photoURL || ''} alt="user" />
                  <div>
                      <button onClick={() => auth.signOut()}>Sign Out</button>
                  </div>
          {/* Display user information or other content */}
        </div>
      ) : (
        <div>
          <h1>Welcome to Our App!</h1>
          <button onClick={handleGoogleSignIn}>Sign in with Google</button>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
